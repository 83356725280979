<script lang="ts" setup>
import type { SwiperOptions } from 'swiper'

import BrandCard from '~/components/cards/BrandCard.vue'
import BasicButton from '~/components/form-elements/BasicButton.vue'
import Page from '~/enums/page'
import type { BrandData } from '~/types/brands'

withDefaults(
  defineProps<{
    brandsData: BrandData[]
    showButton?: boolean
    title: string
  }>(),
  { showButton: true }
)

const brandsPath = Page.Brands
const galleryOptions: SwiperOptions = {
  breakpoints: {
    1020: { slidesPerGroup: 1, slidesPerView: 8 },
    960: { slidesPerGroup: 1, slidesPerView: 7 },
    768: { slidesPerGroup: 1, slidesPerView: 6 },
    480: { slidesPerGroup: 1, slidesPerView: 5.3, spaceBetween: 12 },
    360: { slidesPerGroup: 1, slidesPerView: 4.3, spaceBetween: 12 },
    0: { slidesPerGroup: 1, slidesPerView: 1, spaceBetween: 12 }
  },
  lazy: true,
  spaceBetween: 16
}

const router = useRouter()
</script>

<template>
  <section v-if="brandsData.length">
    <div class="container">
      <Heading element="h2" :path="brandsPath" :title="title" title-class="h1">
        <template v-if="showButton" #buttons>
          <div class="flex cursor-pointer items-center justify-center" @click="router.push(brandsPath)">
            <span class="hidden md:mr-2.5 md:block">Все бренды</span>
            <BasicButton
              :aria-label="title"
              color="blue"
              icon="outlined/east"
              is-icon-only
              is-small
            />
          </div>
        </template>
      </Heading>
    </div>

    <Gallery :options="galleryOptions">
      <BrandCard v-for="x in brandsData" :key="x.id" :brand-data="x" class="swiper-slide max-8-slides" />
    </Gallery>
  </section>
</template>

<style lang="scss" scoped>
@use 'assets/css/gallery';
</style>
