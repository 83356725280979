<script lang="ts" setup>
import ImageType from '~/enums/image-type'
import helpers from '~/helpers'
import type { BrandData } from '~/types/brands'

const props = defineProps<{
  brandData: BrandData
}>()

const imageTypes = [ImageType.Thumbnail144p, ImageType.Original]

const imageUrl = computed(() => {
  const imageUrls = helpers.getImageUrls(props.brandData.relationships.images.data)

  return imageUrls ? helpers.getImageUrl(imageUrls, imageTypes) : null
})
const name = computed(() => props.brandData.attributes.name)
const path = computed(() => helpers.getBrandPath(props.brandData))
</script>

<template>
  <NuxtLink class="block rounded-2xl bg-white p-2 sm:rounded-xl sm:p-4" :to="path">
    <div class="relative overflow-hidden pb-[100%]">
      <img
        v-if="imageUrl"
        v-lazy="imageUrl"
        :alt="name"
        class="absolute size-full min-h-full object-contain"
        loading="lazy"
      >
    </div>
  </NuxtLink>
</template>
